import { useQuery } from '@tanstack/vue-query';
import { Type, type Static } from '@sinclair/typebox';
import { validatorFactory, validatorFactoryArray } from '@laam/lib/validator';
import { useRoute } from 'vue-router';
import * as Sentry from '@sentry/vue';
import {
	brandSchema,
	categoriesResponseSchema,
	type BrandsResponse,
	type CategoriesResponse,
	type CategoryTreeResponse,
} from '@laam/cms-shared';
import { RedirectError } from '~/utils/redirect-error';
import { containCapitalLetters } from '~/utils/helpers';

const brandMetadataValidator =
	validatorFactoryArray<BrandsResponse>(brandSchema);

interface MetadataStoreLookup {
	[key: number]: string;
}

export const useBrandOfDrop = (dropHandle: Ref<string>) => {
	const { data, isLoading, suspense, error } = useQuery<BrandsResponse>({
		queryKey: ['brand', dropHandle],
		queryFn: async () => {
			const api = createApiInstance();
			const dropData = await api.get('/v1/drops?handle=' + dropHandle.value);
			if (dropData.status === 200) {
				if (!Array.isArray(dropData.data)) {
					const err = new Error('Failed to verify drop data');
					Sentry.captureException(err);
					throw err;
				}
				let drop: DropMetadata;
				try {
					drop = dropMetadataValidator.verify(dropData.data[0]);
				} catch (e) {
					const err = new Error('Failed to verify drop data');
					Sentry.captureException(err, (scope) => {
						scope.setContext('errors', {
							error: e,
						});
						return scope;
					});
					throw err;
				}
				const brandData = await api.get('/v1/brands?id=' + drop.brand_id);
				if (brandData.status === 200 && Array.isArray(brandData.data)) {
					try {
						return brandMetadataValidator.verify(brandData.data);
					} catch (e) {
						const err = new Error('Failed to verify brand data');
						Sentry.captureException(err, (scope) => {
							scope.setContext('errors', {
								error: e,
							});
							return scope;
						});
						throw err;
					}
				}
				const err = new Error('Failed to fetch brand data on drops');
				Sentry.captureException(err, (scope) => {
					scope.setContext('drop', { ...drop });
					return scope;
				});
			}
			throw new Error('Invalid response from server');
		},
	});
	return { data, isLoading, suspense, error };
};

const getBrandMetadata = async (handle: string) => {
	const api = createApiInstance();
	const response = await api.get(`/v1/brands?brand_handle=${handle}`);
	const data = await response.data;
	if (response.status === 200 && Array.isArray(data)) {
		const body = data;
		// Hanldled error reporting to sentry on PLP
		const payload = brandMetadataValidator.verify(body)[0];
		if (!payload) {
			throw new Error('Failed to verify brand data');
		}
		return {
			id: payload.id,
			handle: payload.handle,
			productCount: payload.product_count,
			title: payload.name,
			subtitle: payload.product_count.toLocaleString('en-US'),
			description: payload.description,
		};
	}
	throw new Error('Invalid response from server');
};

const nodeMetadataValidator = validatorFactoryArray<CategoriesResponse>(
	categoriesResponseSchema,
);

const getNodeMetadata = async (handle: string) => {
	if (containCapitalLetters(handle)) {
		handle = handle.toLowerCase();
		throw new RedirectError(`/nodes/${handle}`);
	}
	const api = createApiInstance();
	const response = await api.get(`/v1/nodes?handle=${handle}`);
	const data = await response.data;
	if (response.status === 200 && Array.isArray(data)) {
		// Hanldled error reporting to sentry on PLP
		const payload = nodeMetadataValidator.verify(response.data);
		const node = payload[0]!;
		return {
			id: node.id,
			title: node.title,
			subtitle: node.product_count?.toLocaleString('en-US') || '',
			description: node.description,
			meta_description: node.meta_description,
			meta_title: node.meta_description,
			seo_meta_title: node.seo_meta_title as MetadataStoreLookup | undefined,
			seo_meta_description: node.seo_meta_description as
				| MetadataStoreLookup
				| undefined,
		};
	} else if (response.status === 303) {
		try {
			const payload = nodeMetadataValidator.verify(response.data);
			const node = payload[0]!;
			const redirectLocation = `/nodes/${node.handle}`;
			throw new RedirectError(redirectLocation);
		} catch (e) {
			if (e instanceof RedirectError) {
				throw e;
			}
			throw new Error('Failed to validate node data');
		}
	}
	throw new Error('Invalid response from server');
};

const dropMetadataSchema = Type.Object({
	drop_id: Type.Number(),
	brand_id: Type.Number(),
	partner_id: Type.Number(),
	shopify_id: Type.Number(),
	handle: Type.String(),
	title: Type.String(),
	description: Type.String(),
	product_count: Type.Optional(Type.Number()),
	meta_title: Type.Optional(Type.String()),
	meta_description: Type.Optional(Type.String()),
	seo_meta_title: Type.Optional(Type.Unknown()),
	seo_meta_description: Type.Optional(Type.Unknown()),
});

export type DropMetadata = Static<typeof dropMetadataSchema>;

const dropMetadataValidator =
	validatorFactory<DropMetadata>(dropMetadataSchema);

const getDropMetadata = async (handle: string) => {
	const api = createApiInstance();
	const response = await api.get(`/v1/drops?handle=${handle}`);
	const data = await response.data;
	if (response.status === 200 && Array.isArray(data)) {
		const body = data[0];
		// Hanldled error reporting to sentry on PLP
		const payload = dropMetadataValidator.verify(body);
		const title = payload.title.replace('__', '').split('-').join(' ');
		return {
			id: payload.drop_id,
			title: title,
			subtitle: payload.product_count?.toLocaleString('en-US') || '',
			description: payload.description,
			meta_description: payload.meta_description,
			meta_title: payload.meta_description,
			seo_meta_title: payload.seo_meta_title as MetadataStoreLookup | undefined,
			seo_meta_description: payload.seo_meta_description as
				| MetadataStoreLookup
				| undefined,
		};
	}
	throw new Error('Invalid response from server');
};

export interface SeoData {
	title: string;
	subtitle: string;
	description: string;
	id: number;
	meta_description?: string;
	meta_title?: string;
	seo_meta_title?: {
		[key: number]: string;
	};
	seo_meta_description?: {
		[key: number]: string;
	};
}

const getSeoData = async (handle: string, route: string) => {
	if (route === 'brands-handle') {
		return getBrandMetadata(handle!);
	} else if (route === 'nodes-handle') {
		return getNodeMetadata(handle!);
	} else if (route === 'drops-handle') {
		return getDropMetadata(handle!);
	} else {
		return;
	}
};

export const useSeoData = (handle: Ref<string>, enabled: Ref<boolean>) => {
	const route = useRoute();
	const { data, isLoading, suspense, error } = useQuery({
		queryKey: ['seo', handle],
		queryFn: async () => {
			return getSeoData(handle.value, route.name as string);
		},
		retry: false,
		enabled: enabled,
	});
	return { data, isLoading, suspense, error };
};

// const NodeSchema = Type.Object({
// 	id: Type.Number(),
// 	title: Type.String(),
// 	parent_id: Type.Number(),
// 	logo: Type.String(),
// 	image: Type.String(),
// 	valhalla_score: Type.Number(),
// 	product_count: Type.Number(),
// 	node_level: Type.Number(),
// });

// const CategoriesSchema = Type.Array(
// 	Type.Object({
// 		NodeSchema,
// 		children: Type.Object({
// 			NodeSchema,
// 			children: NodeSchema,
// 		}),
// 	}),
// );

export async function fetchCategories() {
	const api = createApiInstance();
	const response = await api.get('/v1/hierarchies');

	if (response.status === 200) {
		const data = await response.data;
		return data as CategoryTreeResponse[];
	}

	throw new Error('Invalid response from server');
}

export const useFetchCategories = () => {
	const { data, isLoading, suspense, error } = useQuery({
		queryKey: ['categories'],
		queryFn: fetchCategories,
		placeholderData: [
			{
				id: 1,
				title: 'Women',
				parent_id: 0,
				logo: 'https://cdn.shopify.com/s/files/1/2337/7003/files/6811921219668.png',
				image:
					'https://cdn.shopify.com/s/files/1/2337/7003/products/52c070855923826388a899ff459058ca.jpg?v=1706871237',
				valhalla_score: 49425284,
				product_count: 58227,
				node_level: 1,
				children: [],
				handle: 'women-1',
			},
			{
				id: 21,
				title: 'Men',
				parent_id: 0,
				logo: 'https://cdn.shopify.com/s/files/1/2337/7003/files/6834102829140.png',
				image:
					'https://cdn.shopify.com/s/files/1/2337/7003/products/0002_DSC03848.jpg?v=1671792458',
				valhalla_score: 1288303.5,
				product_count: 14356,
				node_level: 1,
				children: [],
				handle: 'men-21',
			},
			{
				id: 10000,
				title: 'Kids',
				parent_id: 0,
				logo: '',
				image: '',
				valhalla_score: 0,
				product_count: 5357,
				node_level: 0,
				children: [],
				handle: 'kids-10000',
			},
		],
		refetchOnMount: false,
		staleTime: 30000,
	});

	return { data, isLoading, suspense, error };
};

export interface AllBrands {
	description: string;
	handle: string;
	id: number;
	logo: string;
	name: string;
	product_count: number;
}

export async function fetchAllBrands(): Promise<AllBrands[]> {
	const api = createApiInstance();
	const response = await api.get('/v1/brands');

	if (response.status === 200 && Array.isArray(response.data)) {
		return response.data;
	}

	throw new Error('Invalid response from server');
}

export const useFetchAllBrands = () => {
	const { data, isLoading, suspense, error } = useQuery({
		queryKey: ['all-brands'],
		queryFn: fetchAllBrands,
		refetchOnMount: false,
		staleTime: 30000,
	});
	return { data, isLoading, suspense, error };
};
